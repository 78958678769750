import React, { Fragment, Component } from 'react';
import { Link, graphql } from 'gatsby';
import format from 'date-fns/format';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
import styled from 'styled-components';

//COMPS
import ButtonGrey from '../components/ButtonGrey';
import ShareWidget from '../components/ShareWidget';
import RelatedNews from '../components/RelatedNews';
import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import ContactBanner from '../components/ContactBanner';
import ButtonRed from '../components/ButtonRed';
import SEO from '../components/SEO';

const StyledNewsPostContainer = styled.main`
  margin: 0 auto;
  max-width: 1500px;
  overflow-x: hidden;
  @media (min-width: 1500px) {
    overflow-x: visible;
  }

  h1 {
    font-size: 48px;
    margin-bottom: 3em;
    text-transform: none;
  }
  .content-container {
    margin-top: 2rem;
    .left-container {
      h3 {
        margin-bottom: 8px;
        font-size: 22px;
      }
      p {
        margin-bottom: 4px;
      }
    }
  }
  .bottom-line {
    border-bottom: 2px solid ${colors.lightGray};
    margin-bottom: 1em;
    padding-bottom: 1em;
  }

  .media-widget {
    font-weight: bold;
    padding: 1em 2em;
    border-radius: 0.25rem;
    margin: 0 0 2rem;
    background-image: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .circle-container {
    display: flex;
    flex-direction: column;
    margin: 2em auto;
    max-width: 1150px;
  }
  .black-circle,
  .gray-circle {
    border-radius: 50%;
    width: 540px;
    height: 540px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3em;
    align-self: center;
  }
  .gray-circle {
    background-color: ${colors.secondaryGray};
    margin-bottom: 4em;
    svg {
      color: black;
      height: 45px;
      width: 45px;
    }
  }
  .black-circle {
    background-color: black;
    h2 {
      color: white;
      max-width: 300px;
    }
  }

  @media (min-width: 500px) {
    .black-circle {
      margin-left: auto;
      padding: 0;
      h2 {
        max-width: 420px;
      }
    }
    .gray-circle {
      margin-right: auto;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .content-container {
      display: flex;
      justify-content: center;
    }
    .left-container {
      width: 15%;
      min-width: 210px;
      margin-right: 20px;
    }
    .right-container {
      width: 65%;
    }
  }
`;

const StyledRelatedNews = styled.div`
  margin-bottom: 3em;
  h2 {
    font-size: 40px;
  }
  h2,
  .center {
    text-align: center;
  }
`;

class NewsPostTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false
    };
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/news/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  displayFeaturedImage(post) {
    if (post.better_featured_image === null) {
      return "";
    } else {
      return post.better_featured_image.source_url;
    }
  }

  render() {
    const previewData = this.state.previewPayload;

    let post = this.props.data.wordpressWpNews;
    let tags = this.props.data.allWordpressWpTopic;
    let allSolutions = this.props.data.allWordpressWpOurSolutions;

    var tagTopics = [];
    for (var key in tags.edges) {
      for (var topicKey in post.topic) {
        if (post.topic[topicKey] === tags.edges[key].node.wordpress_id) {
          tagTopics.push(tags.edges[key].node.name);
        }
      }
    }

    var tagServices = [];
    for (var key in allSolutions.edges) {
      for (var topicKey in post.services) {
        if (
          post.services[topicKey] === allSolutions.edges[key].node.wordpress_id
        ) {
          var thisLink =
            "<a href='/services/" +
            allSolutions.edges[key].node.slug +
            "'>" +
            allSolutions.edges[key].node.name +
            '</a><br/>';
          tagServices.push(thisLink);
        }
      }
    }

    //create array of all tags
    var taglabels = [];
    for (var key in tags.edges) {
      taglabels[tags.edges[key].node.wordpress_id] = tags.edges[key].node.name;
    }

    //create array of all services
    var solutionName = [];
    for (var key in allSolutions.edges) {
      solutionName[allSolutions.edges[key].node.wordpress_id] =
        allSolutions.edges[key].node.name;
    }
    return (
      <StyledNewsPostContainer className="animate">
        <SEO
          isBlogPost={true}
          postData={post}
          postImage={this.displayFeaturedImage(post)}
        />
        <NavBar link="/about/news" name="Company News" />

        <div className="page-container">
          <h1>
            {this.state.isPreviewMode ? previewData.title.rendered : post.title}
          </h1>
          <div className="content-container">
            <div className="left-container">
              <div className="sub-div bottom-line">
                <h3>Date</h3>
                <p className="date">{post.acf.display_date}</p>
              </div>
              <div className="share sub-div">
                <h3>Share</h3>
                <ShareWidget/>
              </div>
            </div>
            <div className="right-container">
              <div className="postContent">
                {ReactHtmlParser(
                  this.state.isPreviewMode
                    ? previewData.content.rendered
                    : post.content
                )}
              </div>
            </div>
          </div>
          <div className="circle-container">
            <div className="black-circle">
              <h2>Looking for Press Information?</h2>
              <div className="signupform">
                <ButtonGrey
                  pageLink="/about/press-room"
                  textLabel="Go To Press Room"
                />
              </div>
            </div>
            <div className="gray-circle">
              <h2>We Are Social</h2>
              <ShareWidget />
            </div>
          </div>
          <StyledRelatedNews>
            <h2>More Articles Like This</h2>
            <RelatedNews
              type="news"
              slug={post.slug}
              topic={post.topic}
              services={post.our_solutions}
              vertical={post.vertical}
            />
            <div className="center">
              <ButtonRed pageLink="/about/news" textLabel="See All News" />
            </div>
          </StyledRelatedNews>
          <ContactBanner />
        </div>
      </StyledNewsPostContainer>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    wordpressWpNews(slug: { eq: $slug }) {
      title
      topic
      our_solutions
      vertical
      type
      content
      date
      slug
      better_featured_image {
        source_url
        description
        caption
      }
      acf {
        seo_title
        seo_canonical
        seo_description
        display_date
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    allWordpressWpOurSolutions {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
    allWordpressWpTopic {
      edges {
        node {
          id
          name
          slug
          description
          wordpress_id
        }
      }
    }
  }
`;

export default NewsPostTemplate;
